import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { GatsbyImageFocused } from '@/components/GatsbyImageFocused'
import { bezier, mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data?: Queries.MissionArticleFragment['heroImage'] | null[]
}

export const MissionArticleHeroImages = ({
  data,
}: Props): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0)

  const interval = useRef<ReturnType<typeof setInterval>>()

  const setPlaying = useCallback(() => {
    if ((data?.length || 0) > 1) {
      clearInterval(interval.current)
      interval.current = setInterval(() => {
        setActiveIndex(prev =>
          prev < (data?.length || 0) - 1 ? prev + 1 : 0
        )
      }, 5000)
    }
  }, [data?.length])
  useEffect(() => {
    setPlaying()
    return () => {
      clearInterval(interval.current)
    }
  }, [setPlaying])

  const styles = {
    container: css`
      grid-column: 1 / 2;
      grid-row: 1 / 4;
      align-self: flex-start;
      position: relative;
      display: grid;
      grid-template: 1fr / 1fr;
      ${mq().ms} {
        grid-column: 1 / -1;
        grid-row: auto;
      }
    `,
    figure: (active: boolean) => css`
      grid-column: 1 / -1;
      grid-row: 1 / 3;
      display: grid;
      grid-template-rows: auto 1fr;
      margin: 0;
      max-height: 100%;
      opacity: 0;
      transition: opacity 1000ms ${bezier.easeOut};
      ${active &&
      css`
        z-index: 2;
        opacity: 1;
      `}
    `,
    image: css`
      overflow: hidden;
      ${mq().ms} {
        height: 67vw;
      }
    `,
    caption: css`
      font-style: italic;
      font-size: var(--fs-15);
      margin: 0.5em 0;
      color: #444;
    `,
    nav: css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: flex;
      grid-gap: 2px;
      button {
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        background: #00000066;
        transition: background-color 300ms ease;
        svg {
          height: 66.7%;
          transform: scale3d(0.999, 0.999, 1);
          transition: transform 400ms cubic-bezier(0.33, 3, 0.25, 0.5);
          polyline {
            fill: transparent;
            stroke-width: 3;
            stroke: #fff;
            transition: stroke 300ms ease;
          }
        }
        @media (hover: hover) {
          &:hover {
            background: #000000aa;
            svg {
              transform: scale3d(1.125, 1.125, 1);
            }
          }
        }
      }
    `,
  }
  return (
    <div css={styles.container}>
      {data && data.length > 1 && (
        <nav css={styles.nav}>
          <button
            onClick={() => {
              setActiveIndex(
                activeIndex > 0 ? activeIndex - 1 : data?.length - 1
              )
              setPlaying()
            }}
            aria-label="previous"
            data-previous
          >
            <svg
              width="24px"
              height="48px"
              viewBox="0 0 24 48"
              vectorEffect="non-scaling-stroke"
            >
              <polyline
                points="22.5 45.5 1 24 22.5 2.5"
                vectorEffect="non-scaling-stroke"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              setActiveIndex(
                activeIndex < data?.length - 1 ? activeIndex + 1 : 0
              )
              setPlaying()
            }}
            aria-label="next"
            data-next
          >
            <svg
              width="24px"
              height="48px"
              viewBox="0 0 24 48"
            >
              <polyline
                points="1 45.5 22.5 24 1 2.5"
                vectorEffect="non-scaling-stroke"
              />
            </svg>
          </button>
        </nav>
      )}
      {data?.map((heroImage, i) => (
        <figure
          css={() => styles.figure(activeIndex === i)}
          key={i}
        >
          <GatsbyImageFocused
            css={styles.image}
            image={heroImage?.gatsbyImageData}
            loading="eager"
            data={heroImage}
          />
          {heroImage?.title && (
            <figcaption css={styles.caption}>
              {heroImage.title}
            </figcaption>
          )}
        </figure>
      ))}
    </div>
  )
}
